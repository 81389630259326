import { useMemo } from 'react';

const emptyArray = (nbItems: number) => new Array(nbItems).fill(null);

export const useItems = <TItem = any>(
  items?: TItem[] | null,
  minItems = 5
): (TItem | null)[] => {
  const safeItems = useMemo(() => {
    if (!items) {
      return emptyArray(minItems);
    }

    return items;
  }, [items, minItems]);

  return safeItems;
};

import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { Paper } from '@mui/material';
import { RouteLink, Types } from '@scorenco/core';

import { EventBox } from '../../containers';
import { useItems } from '../../hooks';
import { SlideshowInfinite, Wrapper } from '../../primitives';

import {
  GetBestsLivesBySportDocument,
  GetBestsLivesDocument,
} from './LivesSliderSlice.query.generated';

type LivesSliderSliceProps = {
  slice: Types.ComponentSlicesLivesSlider;
};

export const LivesSliderSlice = ({ slice }: LivesSliderSliceProps) => {
  const getLivesBySport = useQuery(GetBestsLivesBySportDocument, {
    variables: {
      limit: slice.limit || 20,
      sportSlug: slice.sportSlug as string,
    },
    skip: !slice.sportSlug,
  });

  const getLives = useQuery(GetBestsLivesDocument, {
    variables: {
      limit: slice.limit || 20,
    },
    skip: !!slice.sportSlug,
  });

  const events = useItems(
    slice?.sportSlug
      ? getLivesBySport.data?.competitions_event_detail_by_live_rating_and_sport
      : getLives.data?.competitions_event_detail_by_live_rating,
    20
  );

  if (events.length === 0) {
    return null;
  }

  const topEvents = events.slice(0, Math.ceil(events.length / 2));
  const lowEvents = events.slice(Math.ceil(events.length / 2));

  return (
    <Wrapper marginY={5} noMargin>
      <SlideshowInfinite
        length={topEvents?.length}
        speed={1}
        elementRemSize={21}
        css={(theme) => css`
          ${theme.breakpoints.down('lg')} {
            mask-image: none;
          }
        `}
      >
        {topEvents?.map((event, idx) => (
          <Paper
            key={event?.id || idx}
            variant="bordered"
            sx={{
              minWidth: '15rem',
              maxWidth: '25rem',
              height: '6rem',
              padding: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0 1rem',
            }}
          >
            <RouteLink href={event?.url}>
              <EventBox event={event} />
            </RouteLink>
          </Paper>
        ))}
      </SlideshowInfinite>
      <SlideshowInfinite
        length={lowEvents?.length}
        speed={0.75}
        elementRemSize={21}
        css={(theme) => css`
          ${theme.breakpoints.down('lg')} {
            mask-image: none;
          }
        `}
      >
        {lowEvents?.map((event, idx) => (
          <Paper
            key={event?.id || idx}
            variant="bordered"
            sx={{
              minWidth: '15rem',
              maxWidth: '25rem',
              height: '6rem',
              padding: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0 1rem',
            }}
          >
            <RouteLink href={event?.url}>
              <EventBox event={event} />
            </RouteLink>
          </Paper>
        ))}
      </SlideshowInfinite>
    </Wrapper>
  );
};

import { css, keyframes } from '@emotion/react';
import { NoSsr } from '@mui/material';
import { Box, BoxProps } from '@mui/system';

export type SlideshowInfiniteProps = BoxProps & {
  length: number;
  elementRemSize?: number;
  speed?: number;
};

export const SlideshowInfinite = ({
  children,
  length,
  elementRemSize = 16,
  speed = 1,
  ...rest
}: SlideshowInfiniteProps) => {
  return (
    <Box
      css={css`
        background: transparent;
        overflow: hidden;
        mask-image: linear-gradient(
          to left,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 1) 90%,
          rgba(255, 255, 255, 0) 100%
        );
      `}
      {...rest}
    >
      <Box
        css={(theme) => css`
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row;
          margin: ${theme.spacing(2)};
          width: max-content;
          animation: ${keyframes`
                0% { transform: translateX(0); }
                100% { transform: translateX(calc(-${elementRemSize}rem * ${length}))}
              `} ${length * (elementRemSize / 4) * (1 / speed)}s linear infinite;

          &:hover {
            animation-play-state: paused;
          }
        `}
      >
        {children}
        <NoSsr>{children}</NoSsr>
      </Box>
    </Box>
  );
};

import { Box, Skeleton } from '@mui/material';

type TeamNameProps = {
  className?: string;
  team?: any;
  clubId?: number;
  showShortName?: boolean;
};

export const TeamName = ({
  className,
  team,
  clubId,
  showShortName,
}: TeamNameProps) => {
  return (
    <Box
      className={[
        'event-box_team',
        className,
        (team?.club_id === clubId || team?.is_team_of_club === 1) && 'bold',
      ]
        .filter(Boolean)
        .join(' ')}
    >
      {((team?.club_id === clubId || team?.is_team_of_club === 1) &&
      showShortName
        ? team?.name_in_club
        : team?.name_in_competition) || <Skeleton sx={{ width: '10rem' }} />}
    </Box>
  );
};

import { css } from '@emotion/react';
import { Divider, Skeleton, Theme } from '@mui/material';
import { Box, BoxProps } from '@mui/system';
import { Types, useTranslation } from '@scorenco/core';

import { ClubLogo, Score } from '../../components';
import { T_KEYS } from '../../translations';

import { TeamName } from './TeamName';

const style = {
  root: (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: 0.2s;
    padding: 0.75rem;
    text-decoration: none;
    color: inherit;

    &:hover {
      background-color: ${theme.vars.palette.background.default};
    }

    .event-box_container {
      display: flex;
      align-items: flex-start;
      min-height: 100%;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
    }

    .event-box_logo {
      width: 1.25rem;
      height: 2rem;
    }

    .event_card_give-score {
      text-align: center;
      font-size: 10px;
      font-style: italic;
    }

    .event-box_scores {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      font-weight: bold;
      min-height: 100%;
    }

    .event-box_date {
      text-align: center;
      font-size: 12px;
      line-height: 1.2;

      line-height: 1.2;
      font-style: italic;

      > *:first-of-type {
        font-size: 14px;
        line-height: 1.2;
        font-style: normal;
        font-weight: 500;
      }
    }

    .event-box_live::before {
      content: '';
      height: 0.5rem;
      width: 0.5rem;
      margin-right: 0.25rem;
      background-color: #cc062a;
      border-radius: 9999px;
      display: inline-block;
    }

    .event-box_team {
      flex: 1;
      font-size: 14px;
      line-height: 1.5;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      padding: 0 0.5rem;
    }
    .bold {
      font-weight: 700;
    }
  `,
};

export type EventBoxProps = BoxProps & {
  event?: Types.Competitions_Event_Detail | null;
  clubId?: number;
  showShortName?: boolean;
};

export const EventBox = ({
  event,
  clubId,
  showShortName = false,
  ...rest
}: EventBoxProps) => {
  const { t } = useTranslation();

  let scoreboard;
  if (event?.status === 'postponed') {
    scoreboard = (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%',
          gap: '1rem',
        }}
      >
        <Divider orientation="vertical" />
        <Box className="event_card_give-score">{t(T_KEYS.EVENT_POSTPONED)}</Box>
      </Box>
    );
  } else if (event?.status === 'withdrawal') {
    scoreboard = (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%',
          gap: '1rem',
        }}
      >
        <Divider orientation="vertical" />
        <Box className="event_card_give-score">
          {t(T_KEYS.EVENT_WITHDRAWAL)}
        </Box>
      </Box>
    );
  } else if (event?.status === 'live') {
    scoreboard = (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%',
          gap: '1rem',
        }}
      >
        <Box className="event-box_scoreboard" sx={{ height: '100%' }}>
          <Box className="event-box_scores">
            <Score
              score={event?.teams?.[0]?.score}
              opponentScore={event?.teams?.[1]?.score}
              sx={{
                width: '1.5rem',
                height: '2rem',
                mb: '0.125rem',
              }}
            />
            <Score
              score={event?.teams?.[1]?.score}
              opponentScore={event?.teams?.[1]?.score}
              sx={{
                width: '1.5rem',
                height: '2rem',
                mt: '0.125rem',
              }}
            />
          </Box>
        </Box>
        <Divider orientation="vertical" />
        <Box className="event-box_live">Live</Box>
      </Box>
    );
  } else {
    scoreboard = (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%',
          gap: '1rem',
        }}
      >
        <Box className="event-box_scoreboard" sx={{ height: '100%' }}>
          <Box className="event-box_scores">
            <Score
              score={event?.teams?.[0]?.score}
              opponentScore={event?.teams?.[1]?.score}
              isWinner={
                event?.status === 'finish' &&
                event?.teams?.[0]?.score > event?.teams?.[1]?.score
              }
              sx={{
                width: '1.5rem',
                height: '2rem',
                mb: '0.125rem',
              }}
            />
            <Score
              score={event?.teams?.[1]?.score}
              opponentScore={event?.teams?.[1]?.score}
              isWinner={
                event?.status === 'finish' &&
                event?.teams?.[0]?.score < event?.teams?.[1]?.score
              }
              sx={{
                width: '1.5rem',
                height: '2rem',
                mt: '0.125rem',
              }}
            />
          </Box>
        </Box>
        <Divider orientation="vertical" />
        <Box className="event-box_date">
          {event?.date ? (
            <Box>
              {new Date(event.date).toLocaleDateString([], {
                month: 'short',
                day: '2-digit',
              })}
            </Box>
          ) : (
            <Skeleton sx={{ width: '5rem' }} />
          )}
          {event?.time ? (
            <Box>
              {new Date(event.time).toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              })}
            </Box>
          ) : (
            <Skeleton sx={{ width: '4rem' }} />
          )}
        </Box>
      </Box>
    );
  }

  return (
    <Box component="a" css={style.root} {...rest}>
      <Box className="event-box_container">
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <ClubLogo
            className="event-box_logo"
            logo={event?.teams?.[0]?.logo}
            name={event?.teams?.[0]?.name_in_competition}
            shieldColor="home"
          />

          <TeamName
            team={event?.teams?.[0]}
            clubId={clubId}
            showShortName={showShortName}
          />
        </Box>
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <ClubLogo
            className="event-box_logo"
            logo={event?.teams?.[1]?.logo}
            name={event?.teams?.[1]?.name}
            shieldColor="away"
          />
          <TeamName
            className="event-box_team-away"
            team={event?.teams?.[1]}
            clubId={clubId}
            showShortName={showShortName}
          />
        </Box>
      </Box>
      {scoreboard}
    </Box>
  );
};
